module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"JMParsons","short_name":"JMParsons","icon":"static/jmparsons-icon.png","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fd55bb78a95f67266f69c1ca8d048578"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-1743605-1","head":false,"anonymize":true,"respectDNT":true,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"jmparsons.com","exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
